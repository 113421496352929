<!--
 * @Description: 单选题
 * @Author: liqin
 * @Date: 2022-02-17 17:32:34
-->
<template>
  <div>
    <div class="mt20">
      <div>
        <span style="color: #999" v-if="!paper.isShowQuestionType">单选题</span>
        {{"\xa0\xa0\xa0"}}
        <span >总分：{{ val.score }} 分</span>
        {{"\xa0\xa0\xa0"}}
        <span class="red">得分：{{val.userGetScore}}</span>
      </div>
      <div class="dian mt10">{{ val.bankName }} {{ "\xa0" }} </div>
    </div>
    <div class="mt5" v-for="(k) in val.options" :key="k.optionId" >
      <el-radio-group v-model="radio"  :disabled="isView">
        <el-radio :label="k.optionSort" ref="radio" :class="val.userOptionIsok==1?'green':'error'">
          <span>{{ k.optionSort }}、 {{ k.optionName }}</span>    
               <!-- :style="radioColor(k.optionSort,index)" -->
        </el-radio>
      </el-radio-group>
    </div>
    <div class="mt10">
      正确答案：{{ val.bankAnswer }}
    </div>
    <div class="mt10">
       <el-button type="primary" size="mini" @click="dialogVisible = true">点击查看答案解析</el-button>
    </div>
     <!-- 答案解析弹框 -->
    <el-dialog
      title="答案解析"
      :visible.sync="dialogVisible"
      >
      <span v-if="val.bankAnswerParse" v-html="val.bankAnswerParse"></span>
      <span v-else style="text-align: center;">无答案解析</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    val: [Object],
    isView: {
      type: Boolean,
      default: () => false
    },
    isAnswer: { // 答案显示
      type: Boolean,
      default: () => false
    },
    isScore: { // 得分显示
      type: Boolean,
      default: () => false
    },
    paper: { // 
      type: Object,
      default: () => {
        return {}
      }
    },
    userPapersId: [String],
    topicOutlineId: {
      type: String,
      default: () =>  {
        return ''
      }
    }
  },
  data() {
    return {
      radio: '',
      dialogVisible: false
    };
  },
  // computed:{
  //   radioColor(){
  //     return (value,index)=>{
  //       // console.log(value,index);
  //       if(this.val.bankAnswer !== value && this.radio ==value && this.radio == '' ){
  //        this.$nextTick(()=>{
  //           let ref=this.$refs
  //           ref.radio[index].$el.firstChild.firstChild.style.backgroundColor='red'
  //        })
  //       }
  //      return this.val.bankAnswer !== value && this.radio ==value  ? 'color:red' : ''
  //     }
  //   }
  // },
  watch: {
    val: {
      deep: true,
      immediate: true,
      handler(val) {
        val.options.forEach(item => {
          item.isCheck == 1 ? this.radio = item.optionSort : ''
        })
      }
    }
  }

};
</script>
<style lang="scss" scoped>
.red {
  color: red;
}
.green ::v-deep.el-radio__input.is-disabled.is-checked .el-radio__inner{
  background-color: #18cca7;
  border-color: #0b8d73;
}
.error ::v-deep.el-radio__input.is-disabled.is-checked .el-radio__inner{
  background-color: #e47a7a;
  border-color: #a62b2b;
}

//pc端
@media only screen and (min-width: 1200px) {
  ::v-deep .el-dialog{
    width: 40% !important;
  }
}
//pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
  ::v-deep .el-dialog{
    width: 40% !important;
  }
}
// 手机端
@media only screen and (max-width: 767px) {
  ::v-deep .el-dialog{
    width: 90% !important;
  }
}
</style>
